import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function Ban(user, cnt){
    if(user != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 7,
                    user: user
                }
            })
            .then(response => {
                if(response.data != -10){
                    Journal(localStorage.getItem('login'), 'Аккаунт пользователя заблокирован. Попытка входа в учетную запись на другом устройстве')
                    this.$data.results[cnt].block = '1'
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}