import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function changeUser(cnt){
    if(this.login != '' && this.tel != '' && this.FIO != ''){
        this.$Progress.start()
        axios.get('/dashboard.php',
            {
                params:
                {
                    type: 19,
                    id: this.changeid,
                    login: this.login,
                    tel:this.tel,
                    FIO:this.FIO,
                    role:this.role,
                    email:this.email,
                    filial: this.selectedFilial.value,
                    pt: this.pt,
                    pd: this.pd,
                    rh: this.rh
                }
            })
            .then(response => {
                if(response.data != -10){
                    Journal(localStorage.getItem('login'),
                        'Изменение учетных данных пользователя: ' + this.results[cnt].login +
                        '. Телефон: ' + this.results[cnt].tel + ' -> ' + this.tel +
                        '. ФИО: ' + this.results[cnt].FIO + ' -> ' + this.FIO +
                        '. Роль: ' + this.results[cnt].role + ' -> ' + this.rolename +
                        '. E-mail: ' + this.results[cnt].email  + ' -> ' +  this.email +
                        '. Филиал: ' + this.results[cnt].filial + ' -> ' + this.selectedFilial.text
                        )
                    this.results[cnt].login = this.login
                    this.results[cnt].tel = this.tel
                    this.results[cnt].FIO = this.FIO
                    this.results[cnt].role = this.rolename
                    this.results[cnt].email = this.email
                    this.results[cnt].filial = this.selectedFilial.text
                    UIkit.notification({message: localizeFilter('Changed'), pos:'bottom-center'})
                    UIkit.modal("#modal-change").hide()
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                    UIkit.modal("#modal-change").hide()
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
    else
        UIkit.notification({message: localizeFilter('NoData'), status:'danger'})
}
