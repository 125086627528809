<template>
    <div class="uk-grid uk-grid-medium" data-uk-grid>
        <div id="modal-change" uk-modal>
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <div class="uk-margin uk-modal-container-large">
                    <h3 class="uk-card-title">{{"NewUser" | localize}}</h3>
                    <div class="uk-grid-small" uk-grid>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"Login" | localize}}</label>
                            <input class="uk-input" type="text" placeholder="" required v-model="login" @blur="checkLogin(login)" :class="checkedLogin">
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"Phone" | localize}}</label>
                            <input class="uk-input" type="text" placeholder="" required pattern="\d+" v-model="tel" @blur="checkPhone(tel)" :class="checkedTel">
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"FIO" | localize}}</label>
                            <input class="uk-input" type="text" placeholder="" required v-model="FIO">
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"Role" | localize}}</label>
                            <select class="uk-select" required v-model="role">
                                <option value="1">{{"Администратор" | localize}}</option>
                                <option value="2">{{"Сотрудник 1 категории" | localize}}</option>
                                <option value="3">{{"Сотрудник 2 категории" | localize}}</option>
                                <option value="4">{{"Модератор" | localize}}</option>
                                <option value="5">{{"Австралия" | localize}}</option>
                            </select>
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label">{{"Email" | localize}}</label>
                            <input class="uk-input" type="email" placeholder="" v-model='email'>
                        </div>
                        <div class="uk-width-1-3@s">
                            <label class="uk-form-label ">{{"Filial" | localize}}</label>
                            <select class="uk-select" v-model="selectedFilial">
                                <option v-for="filial in filials" v-bind:value="filial">
                                    {{ filial.text }}
                                </option>
                            </select>
                        </div>
                        <div class="uk-width-1-3@s" v-if="role == 2 || role == 6">
                                <label class="uk-form-label ">{{"Pt" | localize}}</label>
                                <input class="uk-input" type="text" v-model='pt'>
                        </div>
                        <div class="uk-width-1-3@s" v-if="role == 2 || role == 6">
                            <label class="uk-form-label ">{{"Pd" | localize}}</label>
                            <input class="uk-input" type="text" v-model='pd'>
                        </div>
                        <div class="uk-width-1-3@s" v-if="role == 2 || role == 6">
                            <label class="uk-form-label ">{{"Rh" | localize}}</label>
                            <input class="uk-input" type="text" v-model='rh'>
                        </div>
                    </div>
                    <p class="uk-text-right">
                        <button class="uk-button uk-button-default uk-margin-right uk-modal-close" type="button">{{"Close" | localize}}</button>
                        <button class="uk-button uk-button-primary" @click="changeUser(cnt)" type="button">{{"Save" | localize}}</button>
                    </p>
                </div>
            </div>
        </div>
    <!-- panel -->
        <div class="uk-width-1-1">
            <div class="uk-card-default uk-card-small uk-card-hover">
                <div class="uk-card-header">
                    <div class="uk-grid uk-grid-small">
                        <div class="uk-width-auto">
                            <download-excel
                                :data   = 'results'
                                :fields = 'fields'
                                name = 'Список пользователей.xls'>
                                <a uk-icon="icon: print" uk-tooltip="Выгрузить в Excel" class="uk-margin-small-top"></a>
                            </download-excel>
                        </div>
                        <div class="uk-width-expand">
                            <div class="uk-inline uk-width-1-1">
                                <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: search"></span>
                                <input class="uk-input uk-width-1-1 search-fld" type="text" :placeholder="localizeFilter('Search')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-card-body uk-margin-medium-bottom uk-padding-remove-top">
                    <div class="form-container uk-overflow-auto">
                        <table class="uk-table uk-table-hover uk-table-middle uk-table-divider" style="background-color: white;">
                            <thead>
                                <tr>
                                    <th class="uk-table-shrink">#</th>
                                    <th class="">{{"Login" | localize}}</th>
                                    <th class="">{{"FIO" | localize}}</th>
                                    <th class="">{{"Email" | localize}}</th>
                                    <th class="">{{"Phone" | localize}}</th>
                                    <th class="">{{"Role" | localize}}</th>
                                    <th class="">{{"Filial" | localize}}</th>
                                    <th class="">{{"Activation" | localize}}</th>
                                    <th class="uk-text-nowrap">{{"Manage" | localize}}</th>
                                </tr>
                            </thead>
                            <tbody v-for="(result, cnt) in results"  :id="result.id">
                                <tr>
                                    <td>{{cnt + 1}}</td>
                                    <td>{{result.login || '-'}}</td>
                                    <td>{{result.FIO || '-'}}</td>
                                    <td>{{result.email || '-'}}</td>
                                    <td class="uk-text-nowrap">{{result.tel || '-'}}</td>
                                    <td class="uk-text-nowrap">{{result.role || '-' | localize}}</td>
                                    <td class="uk-text-nowrap">{{result.filial || '-'}}</td>
                                    <td class="uk-text-nowrap">{{result.activation === '0' ? "No" :"Yes" | localize}}</td>
                                    <td>
                                        <ul class="uk-iconnav">
                                            <li><a uk-icon="icon: file-edit" uk-tooltip="Редактировать" @click="show([result.login, result.FIO, result.email, result.tel, result.idrole, result.id, cnt, result.role, result.filial, result.filialid, result.pt, result.pd, result.rh])"></a></li>
                                            <li v-if="result.block === '1'"><a uk-icon="icon: happy" :uk-tooltip="'title:' + localizeFilter('unBan') +'; pos: bottom'" @click="unBan(result.id, cnt, true)"></a></li>
                                            <li v-if="result.block === '0'"><a uk-icon="icon: ban" :uk-tooltip="'title:' + localizeFilter('Ban') +'; pos: bottom'" @click="Ban(result.id, cnt)"></a></li>
                                            <li><a uk-icon="icon: trash" :uk-tooltip="'title:' + localizeFilter('Delete') +'; pos: bottom'" @click="deleteUser(result.id, cnt)"></a></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import localizeFilter from '@/filter/localize.filter'
import getUsers from '@/config/GetUsers.dashboard'
import deleteUser from '@/config/deleteUser.dashboard'
import unBan from '@/config/unBan.dashboard'
import Ban from '@/config/Ban.dashboard'
import checkLogin from '@/config/checkLogin.dashboard'
import checkPhone from '@/config/checkPhone.dashboard'
import changeUser from '@/config/ChangeUser.dashboard'
import insertMark from '@/config/InsertMark.dashboard'
import getFilials from '@/config/GetFilials.dashboard'
export default {
    data: () => ({
        login: '',
        tel: '',
        FIO: '',
        role: '',
        email: '',
        pt:'',
        pd:'',
        rh:'',
        results: [],
        checkedLogin: '',
        checkedTel: '',
        changeid: '',
        cnt: '',
        rolename:'',
        selectedFilial:'',
        filials:'',
        fields : {
            'ID' : 'id',
            'Логин' : 'login',
            'ФИО' : 'FIO',
            'Филиал' : 'filial',
            'Телефон' : 'tel',
            'Электронная почта' : 'email',
            'Роль' : 'role',
            'Блокировка' : 'block',
            'Активация' : 'activation'
        }
    }),
    methods:{
        getUsers, localizeFilter, deleteUser, unBan, Ban, checkLogin, checkPhone, changeUser, insertMark, getFilials,
        show(param){
            UIkit.modal("#modal-change").show()
            console.log(param)
            this.login = param[0],
            this.tel = param[3],
            this.FIO = param[1],
            this.role = param[4],
            this.email = param[2],
            this.changeid = param[5]
            this.cnt = param[6]
            this.rolename = param[7]
            this.pt = param[10],
            this.pd = param[11],
            this.rh = param[12],
            this.$data.selectedFilial =  {value: param[9], text: param[8]}
        }
    },
    beforeMount(){
        this.getUsers(),
        this.getFilials()
    }
}
</script>
